@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0 8px;
  border: none;
  overflow-y: auto;
  height: 50vh;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.groupContainer {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  
  & .groupLabel {
    font-size: 14px;
    line-height: 24px;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey700);
    display: flex;
    justify-content: space-between;
    &:focus,
    &:hover {
      color: var(--marketplaceColorDark);
    }
  }

  & .groupItem {
    padding: 12px 0 0 12px;
  }
}

.subGroupContainer {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  
  & .subGroupLabel {
    font-size: 14px;
    line-height: 24px;
    font-weight: var(--fontWeightBold);
    color: var(--colorGrey700);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:focus,
    &:hover {
      color: var(--marketplaceColorDark);
    }
  }

  & .subGroupItem {
    padding: 12px 0 0 12px;
  }
}

.groupItem {
  display: none;
}

.isOpen {
  display: block;
}